@import './../../../../styles/common-variables.scss';

.childrenContainer {
  position: relative;
  z-index: 3;
}

.snowStorm {
  position: relative;
  z-index: 2;

  .snowBank {
    position: absolute;
    left: 0;
    right: 0;

    .snowflake {
      position: absolute;
      display: inline-block;
      opacity: 0;

      $list-items-count: 25; // Replace with the number of items in your list
      $animation-delay-step: 0.5s;
      z-index: 1;

      animation: 15s linear makeItSnow forwards;

      // Loop through and apply delay dynamically
      @for $i from 1 through $list-items-count {
        &:nth-child(#{$i}) {
          &:nth-child(even) {
            animation-delay: $i * $animation-delay-step;
          }

          &:nth-child(odd) {
            animation-delay: $i / 2 * $animation-delay-step;
          }
        }
      }
    }
  }
}

@keyframes makeItSnow {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(120vh) rotate(360deg);
    opacity: 0;
  }
}
