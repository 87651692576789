@import './../../../../styles/common-variables.scss';

footer {
  background-color: $color_Black1;
  color: white;
  padding: 2rem 0;

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
  }

  .nav-link {
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }

  .icon {
    padding: 2rem 0 1rem 0;
    width: 3rem;
    margin: auto;
  }
}
