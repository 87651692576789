@import './../../../../styles/common-variables.scss';

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  padding-top: 0;
}

html {
  font-size: 14px;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.logo {
  width: 180px;
}

.nav-link {
  margin: 0 0.8em;
  font-size: 1.3em;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
}

.nav-link:hover {
  color: $color_Blue;
}

.header-button,
.login-button {
  font-size: 1.3rem;
  margin: 0.2rem 0 0 1.2rem;
  padding-left: 1.2rem !important;
  padding-right: 1.2rem !important;
}

.login-button {
  margin-left: 0.5rem;
}

/* Mobile */
@media (max-width: 768px) {
  .home-top-navbar .nav-link {
    display: inline-block;
    margin: 0.25rem 0;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
