@import './../../../styles/common-variables.scss';

.hero {
  width: 70%;
  vertical-align: middle;
  margin-top: 2rem;
}

.hero-row {
  padding: 4rem 1rem;
}

h1 {
  color: $color_Black1;
  font-size: 4rem;
  padding-top: 2rem;
}

h2 {
  color: $color_Black1;
  font-size: 2rem;
  padding-top: 0rem;
}

select {
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
}

.light-blue {
  color: $color_Blue !important;
}

.green {
  color: #6ab1a9;
}

.state-search {
  margin: 1rem;
}

.search-button {
  clear: both;
}

.hero-row h1 {
  text-align: center;
}

.hero-row h3 {
  font-size: 1.4rem;
  text-align: center;
  /* font-weight: 400; */
  color: #5a6776;
}

.advisor-home-cell {
  border: solid 1px #8e949b;
  border-radius: 10px;
  padding: 1.5rem;
  position: relative;
  text-align: left;
}

.advisor-home-cell h2 {
  font-size: 1.5rem !important;
}

.advisor-home-cell .headshot {
  height: 12rem;
  display: block;
  margin: auto;
}

.advisor-home-cell .btn {
  width: 70%;
  margin-top: 1rem;
}

.advisor-home-cell .btn .view-profile {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
}

.advisor-home-cell .next-available {
  font-size: 80%;
  font-weight: 400;
}

.advisor-home-cell a {
  text-decoration: none;
}

.advisor-home-cell h1 {
  font-size: 1.5rem;
  font-weight: 700;
}

.advisor-home-cell h2 {
  font-size: 1.2rem;
  font-weight: 400;
}

.advisor-home-cell li {
  font-size: 1rem;
}

.advisor-home-cell .results-reviews span {
  padding: 0;
  margin: 0;
}

.home-page {
  .row {
    text-align: center;

    h2 {
      font-size: 3rem;
    }
  }
}

.home-section-head {
  margin: 2rem 0 1rem 0;
}

.how-it-works h3 {
  margin-bottom: 1rem;
  font-weight: bold;
}

.how-it-works-step {
  text-align: center;
  margin: 0 3rem;
}

.how-it-works .col {
  padding-bottom: 2rem;
}

.how-it-works .col img {
  height: 4rem;
  float: right;
}

.hiw-icon {
  font-size: 6rem;
  margin-bottom: 2rem;
  color: #6ab1a9;
}

.how-we-can-help i {
  font-size: 5rem;
  margin-bottom: 1rem;
  color: $color_Blue;
}

.cta {
  margin-top: 3rem;
  text-align: center;
}

.cta p {
  font-size: 1.4rem;
}

.price {
  font-weight: 700;
  color: #6ab1a9;
}

.price-box {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: solid 1px $color_Gray_Light6;
  border-radius: 10px;
  box-sizing: content-box;
  padding: 0;
  margin: 0 1rem 1rem 1rem;
  transition: box-shadow 250ms ease-in-out;

  &:hover {
    box-shadow: $boxShadow1;
  }
}

.price-header {
  border-radius: 10px 10px 0 0;
  background-color: $color_Blue;
  color: white;
  padding: 1.5rem;
  height: 12rem;
}

.price-header h3 {
  font-weight: 700;
  font-size: 3rem;
}

.price-header h4 {
  font-weight: 700;
  font-size: 4rem;
  color: #6ab1a9;
}

.price-box button {
  margin-bottom: 1rem;
}

.price-details {
  padding: 1rem 1rem 0 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.price-details hr {
  width: 80%;
  height: 1px;
  background-color: #eee;
  color: #ccc;
  margin: 1.5rem auto 1rem auto;
}

.price-details h2 {
  margin-bottom: 1rem;
}

.price-details .price {
  font-size: 3rem;
  font-weight: bold;
  color: $color_Black1;
}

.price-details form {
  margin: 0;
}

.price-details .hour {
  font-size: 1.2rem;
  font-weight: 400;
  color: #777;
}

.price-details .state-search {
  margin: 0 auto 1rem auto;
}

.price-details .btn {
  width: 50%;
  margin: 0 auto 2rem auto;
  display: block;
}

.price-details .price-footer {
  margin-top: auto;
}

.topSection {
  min-height: 460px;

  .plan-features {
    padding: 0;

    li {
      text-align: center;
      list-style-type: none;
      line-height: 200%;
      margin: 0;
      padding: 0;
    }
  }
}

.price-details p {
  text-align: center;
}

.expert-icon {
  text-align: center;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.4rem;
}

.expert-icon img {
  width: 4rem;
}

.last-button select,
.last-button .btn {
  margin: 0.5rem auto;
  font-size: 1.5rem;
  border-radius: 3rem;
}

/* .accordion {
	--bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	--bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
} */

.contact-us #message {
  height: 10rem;
}

.contact-us button {
  width: 10rem;
  margin-left: auto;
  font-size: 1.4rem;
}

.contact-us p {
  margin-top: 2rem;
}

.logout-page p {
  height: 20rem;
  font-size: 1.4rem;
  margin-top: 2rem;
}

.booked-page h1 {
  font-size: 3rem;
  font-weight: bold;
}

.booked-page p {
  margin-top: 2rem;
}

.booked-page .btn {
  margin: 1rem 0 2rem 0;
}

.booked-image {
  width: 100%;
}

.line-box {
  border: #eee 1px solid;
  border-radius: 1rem;
  padding: 2rem;
}

.register-box {
  width: 100%;
  max-width: 30rem;
  margin: auto;
  margin-top: 6rem;
}

.register-box h2 {
  margin-bottom: 2rem;
}

.register-box img {
  width: 12rem;
  margin-bottom: 3rem;
}

.register-box button {
  width: 12rem;
}

.info-box {
  background-color: #f8fafb;
  border-radius: 1rem;
  padding: 2rem;
}

.info-box .info-box-details {
  margin-left: 5rem;
}

.info-box .info-box-icon {
  font-size: 4rem;
}

.register-footer a {
  text-decoration: none;
}

.register-footer vr {
  border: solid 1px red;
  padding: 1rem;
}

.results-name a {
  color: inherit;
}

.results-name a:hover {
  color: #0077cc;
}

.results-reviews {
  margin-bottom: 0.5rem;
}

.results-reviews span {
  font-weight: 400;
  font-size: 100%;
  padding-right: 1rem;
}

.results-reviews i {
  color: #6ab1a9;
}

.results-reviews i.fa-eye-slash {
  color: #777;
}

.results-att {
  width: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.disclaimer-row .btn-link {
  font-size: 100%;
  color: #0077cc;
}

.disclaimer-body p {
  font-size: 90%;
}

.home-stats strong {
  font-size: 150%;
}

.home-stars {
  position: relative;
  display: inline-block;
  margin: 0 auto;
}

.home-stars i {
  color: #e9d482;
  font-size: 3rem;
}

.home-stars-overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.testimonial {
  border: solid 1px #8e949b;
  border-radius: 10px;
  padding: 1.5rem;

  .testimonialText {
    @media (min-width: $gridSize_Lg) {
      min-height: 200px;
    }
  }

  .testimonial-pics {
    display: flex;
    justify-content: center;

    i {
      color: $color_Blue;
      font-size: 6rem;
      margin-right: -1rem;
      z-index: -1;
    }

    img {
      max-width: 6rem;
    }

    > img:first-child {
      margin-right: -1rem;
      z-index: 1;
    }
  }

  .testimonial-stars {
    color: #e9d482;
    font-size: 1.5rem;
  }
}

.disclaimer-text {
  margin-top: 1rem;
  font-size: 80%;
}

.affiliate-disclaimer {
  font-size: 80%;
  padding: 0 2rem 2rem 2rem;
}

/* Desktop stuff*/
@media (min-width: 767px) {
  .disclaimer-modal {
    max-width: 760px;
  }
}

/* MOBILE STUFF */
@media (max-width: 1400px) {
  .price-box {
    margin: 1rem auto;
    width: 45%;
  }
}

/* MOBILE STUFF */
@media (max-width: 767px) {
  .hero-row {
    padding-top: 0;
  }

  .price-box {
    margin: 1rem auto;
    width: 90%;
  }

  .last-button {
    width: 90%;
  }

  .last-button a {
    font-size: 1.5rem;
  }
}
