@import './../../../../styles/common-variables.scss';

.shadow-box {
  margin-bottom: 2rem;
  padding: 2rem;
  border: 2px solid $color_Black1;
  box-shadow: 4px 4px 0px $color_Black1;
  border-radius: 10px;
}

.btn {
  border-radius: 100px;
}

.bg-secondary {
  background-color: #eef4f6 !important;
}

.bg-dark {
  background-color: #2d4c6c !important;
}

.account-menu .nav-link {
  font-size: 1.4rem;
  color: #555;
  margin-top: 1rem;
}

.account-menu .nav-link.active,
.mobile-menu .nav-link.active {
  color: white;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  margin-left: -3rem;
  padding-left: 4rem;
  font-weight: bold;
  background-color: #6ab1a9;
}

.mobile-logo {
  width: 180px;
  margin: 0 0 0 1rem;
}

.impersonate {
  font-size: 1.5rem;
  line-height: 1rem;
  text-decoration: none;
}

@keyframes slideInImpersonateLabel {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (min-width: 1200px) {
}
