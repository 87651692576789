@import './../styles/common-variables.scss';

/* Provide sufficient contrast against white background */
* {
  font-family: 'brandon-grotesque', sans-serif;
}

a,
.btn-link {
  color: $color_Blue5;
  text-decoration: none;
}

a:hover,
.btn-link:hover {
  color: #258cfb;
}

p,
div {
  font-size: 1.2rem;
}

.btn:focus,
.btn:active:focus,
.btn-link.nav-link:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow:
    0 0 0 0.1rem white,
    0 0 0 0.25rem #258cfb;
}

code {
  color: #e01a76;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

input,
textarea,
select {
  margin-bottom: 0.5rem;
}

.thin {
  font-weight: 300;
}

.page-break {
  page-break-before: always;
}

strong {
  font-weight: 600;
}

hr {
  opacity: 100%;
}

.btn-primary {
  color: #fff;
  background-color: $color_Blue;
  border-color: $color_Blue;
}

.btn-primary:hover {
  background-color: #2d4c6c;
  border-color: #2d4c6c;
}

.btn-light {
  background-color: #fff;
  color: $color_Blue;
  border: solid 1px $color_Blue;
}

.brandon {
  font-family: 'brandon-grotesque', sans-serif;
  font-weight: 400;
  /* 100, 400, 700, 900 for thin, regular, bold, black */
  font-style: normal;
  /* normal, italic */
}

.circle-image {
  border-radius: 50%;
}

.right {
  text-align: right;
}

.full-screen-loading {
  width: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: #8ed0ec;
    margin: -40px 0 0 10px;
  }
}

/* From: https://loading.io/css/ */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    border: 4px solid #cef;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  div:nth-child(2) {
    animation-delay: -0.5s;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* https://loading.io/css/ */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;

  div {
    position: absolute;
    top: 8px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* MOBILE STUFF */
@media (max-width: 767px) {
}

/* Desktop stuff*/
@media (min-width: 767px) {
  .modal-dialog {
    max-width: 760px;
  }
}

.modal-dialog {
  &.thinModal {
    max-width: 400px;
  }
}

.tooltip- {
  &left-aligned {
    text-align: left;
  }

  &wide {
    min-width: 300px;
  }

  &white {
    background-color: white;
    color: $color_Black1;
    border: solid 1px $color_Gray_Light6;
    border-radius: 10px;
    padding: 15px 15px 0 20px;
  }
}

.full-screen-modal {
  max-width: 80%;
  text-align: center;

  img {
    max-width: 100%;
  }

  iframe {
    max-width: 100%;
  }
}

.modal.show {
  @media (max-width: $gridSize_Md) {
    .modal-body {
      max-height: 80vh;
      overflow: auto;
    }
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  // The ReactStrap carousel uses a background image URL to set its chevron icons, so just invert their color
  filter: invert(100%);
  transform: scale(0.6);
}

.carousel-control-prev,
.carousel-control-next {
  color: $color_Black1 !important;

  &::before {
    $offsetCalc: calc(50% - 20px);

    content: '';
    position: absolute;
    left: $offsetCalc;
    top: $offsetCalc;
    bottom: $offsetCalc;
    right: $offsetCalc;
    border: solid 2px $color_Black1;
    border-radius: 50px;
    background-color: transparent;
  }
}

.outletWrapper {
  margin-bottom: $outletContainerOffset;
}

.dropdown-menu {
  button {
    > i {
      min-width: 24px;
    }
  }
}

input {
  &.shortInput {
    max-width: 400px;
  }

  &.hasError {
    border-color: $color_Red1;
  }
}

.input-group {
  width: 150px;

  input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0 !important;
  }
}
