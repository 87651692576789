$color_Blue: #27ace3;
$color_Blue_SlightlyOpaque: rgba(39, 172, 227, 0.25);
$color_Blue2: #ecf6fa;
$color_Blue3: #eff6f9;
$color_Blue4: #27aade;
$color_Blue5: #0366d6;
$color_Black1: #162435;
$color_Green: #6bb1a9;
$color_Green2: #55bf93;
$color_Green_Opaque: rgba(107, 177, 169, 0.25);
$color_Gray_Light1: #999999;
$color_Gray_Light2: #aaaaaa;
$color_Gray_Light3: #cccccc;
$color_Gray_Light4: #e3e3e3;
$color_Gray_Light5: #909090;
$color_Gray_Light6: #b3b3b3;
$color_Gray_Light7: #d9d9d9;
$color_Gray_Light8: #eaeaea;
$color_Orange: #f57c00;
$color_Orange_Opaque: rgba(245, 124, 0, 0.25);
$color_Yellow1: #ffea00;
$color_Red1: #ff5252;
$color_Red1_Opaque: rgba(255, 82, 82, 0.25);

$gridSize_Xs: 576px;
$gridSize_Md: 768px;
$gridSize_Lg: 992px;
$gridSize_Xl: 1200px;

$outletContainerOffset: 100px;

$boxShadow1: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
$boxShadow2: 0px 4px 15px 0px rgba(100, 100, 111, 0.2);

$borderRadius1: 8px;

$zIndex_ConfirmationOverlay: 1060;
$zIndex_NotificationPanel: 1061;
$zIndex_LoadingOverlay: 1065;
$zIndex_ImpersonationLabel: 1050;
