@import './../styles/common-variables.scss';

.accordion-primary {
  border: solid 1px #cbcbcb;
  border-radius: 1rem;
  margin-bottom: 1rem;

  .accordion-item {
    border-radius: 1rem;
  }

  .accordion-header {
    margin: 0;
  }

  .accordion-button:not(.collapsed) {
    background-color: $color_Blue;
    border-radius: 1rem 1rem 0 0;
    color: white;
    font-weight: normal;
  }

  .accordion-button.collapsed {
    background-color: #eaf7fc;
    color: 162435;
    border-radius: 1rem;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 1rem;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
}

.c- {
  &black {
    color: $color_Black1 !important;
  }

  &gray {
    color: $color_Gray_Light1 !important;
  }

  &gray2 {
    color: $color_Gray_Light3 !important;
  }

  &red {
    color: $color_Red1 !important;
  }

  &orange {
    color: $color_Orange !important;
  }

  &blue {
    color: $color_Blue !important;
  }

  &blue2 {
    color: $color_Blue5 !important;
  }

  &green {
    color: $color_Green !important;
  }

  &yellow {
    color: $color_Yellow1 !important;
  }
}

.font-sm {
  font-size: 0.5rem;
}

.font-sm-md {
  font-size: 0.8rem;
}

.font-m {
  font-size: 1rem;
}

.font-l {
  font-size: 1.25rem;
}

.font-l2 {
  font-size: 1.5rem;
}

.font-xl {
  font-size: 2rem;
}

.font-rem- {
  &1 {
    font-size: 1rem;
  }
  &1-5 {
    font-size: 1.5rem;
  }
  &2 {
    font-size: 2rem;
  }
  &3 {
    font-size: 3rem;
  }
  &4 {
    font-size: 4rem;
  }
  &5 {
    font-size: 5rem;
  }
  &6 {
    font-size: 6rem;
  }
  &7 {
    font-size: 7rem;
  }
  &8 {
    font-size: 8rem;
  }
  &9 {
    font-size: 9rem;
  }
  &10 {
    font-size: 10rem;
  }
}

.modal-header {
  padding-bottom: 0.5em;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.isRotating {
  animation: 1500ms linear isRotating infinite forwards;
}

.rotate {
  transition: transform 250ms linear;
}

.rotate-180 {
  transform: rotate(180deg);
}

.basicContainer {
  padding: 15px;
  border: solid 2px $color_Gray_Light3;
  border-radius: $borderRadius1;
  position: relative;

  .containerLabel {
    position: absolute;
    background-color: white;
    padding: 0 8px;
    top: -13px;
    left: 10px;
    font-size: 0.95rem;
    color: $color_Gray_Light1;

    [class*='fa-'] {
      font-size: 1.15rem;
    }
  }
}

@keyframes isRotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-user-select {
  user-select: none;
}

.att-icon {
  color: #6ab1a9;
}

.missing-icon {
  color: #f3705f;
}
