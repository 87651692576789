@import './../../styles/common-variables.scss';

.impersonationLabel {
  animation: slideInImpersonateLabel 250ms 100ms ease-in-out forwards;
  background-color: #ff6d00;
  border-radius: 0 0 0 8px;
  border: solid 2px white;
  border-right-width: 0;
  border-top-width: 0;
  box-shadow: 2px 2px 5px rgba(125, 125, 125, 0.35);
  color: white;
  padding: 4px 12px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  z-index: $zIndex_ImpersonationLabel;

  div {
    font-size: 16px;
    font-weight: 500;
  }

  .closeIcon {
    margin-top: 2px;
    padding: 2px;
    cursor: pointer;
  }
}
